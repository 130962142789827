import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  @page {
    size: portrait;
    /* margin: 3cm; */
    width: 8.5in;
    height: 11in;
  }
`;

export default GlobalStyle;