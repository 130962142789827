// This default export is required in a new `pages/_app.js` file.
import Head from 'next/head'
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from '../src/bugsnag';
import GlobalStyle from '../src/global-style';
import Error from './_error';



const theme = {
  colors: {
    primary: '#0070f3',
  },
}

export default function MyApp ({ Component, pageProps }) {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </ErrorBoundary>
  );
}