import { Component } from 'react';
import ErrorPage from 'next/error'
import Head from 'next/head';
import Image from 'next/image';
import styled from 'styled-components';
import clearMeLogo from '../public/clearme_logo_results.svg';
import Bugsnag from '../src/bugsnag';

const Container = styled.div`
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  padding: 20px 15px 20px 15px;
  text-align: center;
  max-width: 600px;
  color: white;

  h1, h2 {
    color: #C6E2F7;
  }
`;

const globalStyle = `
  body {
    background-color: #072d3e;
  }
`;

export default class Page extends Component {

  static async getInitialProps (ctx) {
    if (ctx.err) {
      Bugsnag.notify(ctx.err);
    }
    const errorProps = await ErrorPage.getInitialProps(ctx);
    if (ctx.query.r) {
      if(!errorProps.props) {
        errorProps.props = {};
      }
      errorProps.props.r = ctx.query;
    }
    return errorProps;
  }

  render (props) {
    return <>
      <Head>
        <title>Clearme</title>
        <link rel="icon" href="/favicon.ico" />
        <style>{globalStyle}</style>
      </Head>
      <Container>
        <Image src={clearMeLogo} width={120} height={120} layout="fixed" alt="ClearMe" unoptimized />
        <h1>Something went wrong<br/> Please try again</h1>
      </Container>
    </>;
  }
}
